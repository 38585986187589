import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Link,
  ListItem,
  OrderedList,
  ResponsiveArray,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import {
  SectionLayout,
  sectionProps,
} from "../../../components/page-sections/info-per-il-pubblico/layout";

type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse";

const expandedProps = {
  _expanded: {
    bg: "utils.offWhite",
    mb: 6,
    borderLeft: "3px solid #2F79D9",
  },
};

const collapsedProps = {
  py: 6,
  borderLeft: "3px solid transparent",
  flexDirection: ["column", null, "row"] as ResponsiveArray<FlexDirection>,
};

type TToggleButton = {
  expanded: boolean;
};

const ToggleButton: React.FC<TToggleButton> = ({ expanded }) => (
  <Button flexShrink={0} w={["100%", null, "auto"]} mt={[3, null, 0]}>
    {expanded ? (
      <>
        <CloseIcon fontSize="11px" mr={"8px"} /> Chiudi
      </>
    ) : (
      <>
        <AddIcon fontSize="11px" mr={"8px"} /> Scopri
      </>
    )}
  </Button>
);

const SicurezzaInRetePage: React.FC = () => {
  return (
    <SectionLayout page="sicurezza-in-rete" title="Sicurezza in rete">
      <Box {...sectionProps}>
        <Heading
          as={"h1"}
          fontSize={["24px", "32px"]}
          fontWeight={"400"}
          textAlign={"left"}
          fontFamily={"body"}
          color={"accent.01"}
        >
          Sicurezza in rete
        </Heading>
        <br></br>
        <Heading as={"h5"} size="md" fontFamily={"body"} color={"accent.01"}>
          I nostri 5 suggerimenti per la sicurezza online
        </Heading>
        <br></br>
        <Text>
          Qui troverai alcuni consigli semplici e utili per orientarti in modo
          più sicuro nella tua vita digitale.
        </Text>
        <Link
          href="/documents/informazioni-per-il-pubblico/5-suggerimenti-sicurezza-online.pdf"
          color="accent.01"
          isExternal
        >
          Scarica il PDF
        </Link>
        <Accordion allowToggle allowMultiple my={"40px"}>
          {/* ITEM #1 */}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton {...collapsedProps} {...expandedProps}>
                  <Box textAlign="left">
                    <Heading
                      as="h3"
                      size="md"
                      fontWeight="400"
                      py={2}
                      fontFamily={"body"}
                      _hover={{ color: "accent.01" }}
                    >
                      1. Proteggi le tue informazioni e i tuoi dispositivi
                    </Heading>

                    <Text fontSize={"15px"} color={"utils.gray.02"} mr={4}>
                      Attivare gli aggiornamenti automatici delle tue
                      applicazioni può darti una maggiore protezione.
                    </Text>
                  </Box>

                  <ToggleButton expanded={isExpanded} />
                </AccordionButton>

                <AccordionPanel pb={"60px"}>
                  <Text>
                    Il numero di dispositivi elettronici che utilizziamo e la
                    quantità di informazioni che conserviamo su di essi è in
                    costante crescita. Questi sono i passaggi che dovresti
                    compiere per aumentare la loro protezione.
                  </Text>

                  <OrderedList>
                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Attiva gli aggiornamenti automatici
                      </Heading>
                      <Text>
                        Gli aggiornamenti del telefono, del computer e delle app
                        di solito includono miglioramenti della sicurezza. Per
                        sfruttarli al meglio già dal primo giorno, controlla le
                        tue impostazioni e, quando possibile, scegli gli
                        aggiornamenti automatici.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Scegli quando connetterti
                      </Heading>
                      <Text>
                        Disattiva l'opzione di connessione automatica al Wi-Fi.
                        In questo modo, potrai controllare quando e su quale
                        rete connetterti. Quando sei connesso ad una rete
                        pubblica, evita di effettuare transazioni bancarie e
                        acquisti online.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Naviga in sicurezza
                      </Heading>
                      <Text>
                        I siti web che iniziano con HTTPS (invece di HTTP) sono
                        solitamente più sicuri. Quando ti trovi su una pagina
                        HTTPS, sul lato sinistro del browser visualizzi il
                        simbolo di un lucchetto. Se il sito inizia con HTTP,
                        generalmente, non è prudente inserire dati personali o
                        bancari (es: credenziali di accesso, estremi della carta
                        di credito o del conto corrente) perché potrebbero
                        essere intercettati da utenti malintenzionati.
                      </Text>
                    </ListItem>
                  </OrderedList>

                  <Heading
                    as="h4"
                    fontSize="lg"
                    fontWeight={"400"}
                    textAlign={"left"}
                    fontFamily={"body"}
                    color={"accent.01"}
                    mt={10}
                    mb={2}
                  >
                    Mantieni il controllo dei tuoi dispositivi
                  </Heading>
                  <Text>
                    Per evitare che qualcuno acceda alle informazioni presenti
                    sui tuoi dispositivi, attiva le funzioni "blocco automatico"
                    e "trova il mio telefono", quando possibile. Con
                    quest'ultima opzione, sarai in grado di bloccare il
                    dispositivo da remoto e perfino effettuare un reset alle
                    impostazioni di fabbrica.
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {/* ITEM #2 */}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton {...collapsedProps} {...expandedProps}>
                  <Box textAlign="left">
                    <Heading
                      as="h3"
                      size="md"
                      fontWeight="400"
                      py={2}
                      fontFamily={"body"}
                      _hover={{ color: "accent.01" }}
                    >
                      2. Sii discreto online e in pubblico
                    </Heading>
                    <Text fontSize={"15px"} color={"utils.gray.02"} mr={4}>
                      Prestare attenzione alle informazioni che condividi sui
                      social media, di persona o al telefono, può aiutarti a
                      migliorare la tua sicurezza e prevenire furti di identità.
                    </Text>
                  </Box>

                  <ToggleButton expanded={isExpanded} />
                </AccordionButton>

                <AccordionPanel pb={"60px"}>
                  <Text>
                    Al giorno d'oggi, essere presenti sui social media e
                    ricevere richieste di contatto o visualizzazioni da parte di
                    estranei è molto comune, motivo per cui dobbiamo stare
                    attenti alle informazioni che condividiamo.
                  </Text>

                  <OrderedList>
                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Online
                      </Heading>
                      <Text>
                        La condivisione delle informazioni sui social media e su
                        altre piattaforme fa ormai parte della nostra vita
                        quotidiana. Presta attenzione a ciò che pubblichi e
                        configura le impostazioni di sicurezza per bloccare
                        l'accesso a chiunque non conosci. In questo modo, gli
                        estranei non saranno in grado di utilizzare le tue
                        informazioni per altri scopi, ad esempio rispondere alle
                        domande di verifica dei tuoi account o creare e-mail di
                        phishing più credibili.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Di persona o per telefono
                      </Heading>

                      <Text>
                        Non condividere informazioni con persone che non
                        conosci. Chiedi maggiori dettagli per accertarti
                        dell’identità di coloro che ti contattano. In caso di
                        dubbio, non fornire alcun dettaglio. Contattali, invece,
                        direttamente tramite i canali ufficiali, ad esempio
                        attraverso il loro sito web.
                      </Text>
                    </ListItem>
                  </OrderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {/* ITEM #3 */}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton {...collapsedProps} {...expandedProps}>
                  <Box textAlign="left">
                    <Heading
                      as="h3"
                      size="md"
                      fontWeight="400"
                      py={2}
                      fontFamily={"body"}
                      _hover={{ color: "accent.01" }}
                    >
                      3. Pensa prima di fare clic o rispondere
                    </Heading>

                    <Text fontSize={"15px"} color={"utils.gray.02"} mr={4}>
                      Sta diventando piuttosto frequente ricevere e-mail,
                      messaggi e chiamate in cui la persona o l'azienda non sono
                      quelle che dichiarano di essere. Apprendi alcuni semplici
                      accorgimenti che ti aiuteranno ad identificarli.
                    </Text>
                  </Box>

                  <ToggleButton expanded={isExpanded} />
                </AccordionButton>

                <AccordionPanel pb={"60px"}>
                  <Text>
                    Situazioni di emergenza e offerte difficili da rifiutare
                    sono alcuni dei metodi più comuni utilizzati nel phishing
                    per impossessarsi delle tue informazioni o assumere il
                    controllo dei tuoi dispositivi. Non cadere in questa
                    trappola: dedica un momento a leggere questi suggerimenti
                    che ti aiuteranno a capire se fare clic o rispondere.
                  </Text>

                  <OrderedList>
                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Se non conosci il mittente dell'e-mail
                      </Heading>
                      <Text>
                        Sposta il mouse sull'indirizzo e-mail o fai clic su
                        Rispondi per vedere il reale mittente. Valuta se
                        l'indirizzo sia credibile e affidabile (ad esempio
                        potrebbe contenere degli errori ortografici) e se
                        corrisponde al nome della persona che ha inviato
                        l'e-mail. HCBE, nell’invio di comunicazioni ai propri
                        clienti, utilizza sempre i seguenti domini di posta
                        elettronica: <strong>@it.hcs.com</strong>
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Se ti chiedono di fare clic, scaricare o rispondere
                      </Heading>
                      <Text>
                        Fai attenzione alle e-mail o ai messaggi che ti chiedono
                        di fare clic su un collegamento, scaricare un file o
                        rispondere con informazioni personali, poiché potrebbero
                        essere tentativi di phishing.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        color={"accent.01"}
                        mt={10}
                        mb={2}
                      >
                        Se c'è un senso di urgenza
                      </Heading>
                      <Text>
                        E-mail, chiamate o messaggi fraudolenti di solito ti
                        chiedono di agire rapidamente. Ad esempio, chiedendo i
                        tuoi dati per darti un premio il prima possibile, per
                        ricevere ulteriori vantaggi o per risolvere un problema
                        di sicurezza.
                      </Text>
                    </ListItem>

                    <Heading
                      as="h4"
                      fontSize="lg"
                      fontWeight={"400"}
                      textAlign={"left"}
                      fontFamily={"body"}
                      color={"accent.01"}
                      mt={10}
                      mb={5}
                    >
                      Ecco alcuni esempi per aiutarti a identificare i casi di
                      furto di identità
                    </Heading>

                    <Heading
                      as="h5"
                      fontSize="lg"
                      fontWeight={"400"}
                      textAlign={"left"}
                      fontFamily={"body"}
                      my={4}
                    >
                      PHISHING
                    </Heading>
                    <Text>
                      Ricevi una e-mail che sembra provenire dal tuo fornitore
                      di energia elettrica. Ti chiede di cliccare su un link per
                      accedere alla tua area personale dove devi confermare i
                      tuoi dati bancari. Sembra urgente perché, a quanto pare,
                      c'è un problema di sicurezza. Prenditi qualche secondo per
                      pensare e non fare subito clic sul link, perché potrebbe
                      trattarsi di phishing. In questo tipo di situazione
                      dovresti contattare il tuo fornitore di energia elettrica
                      attraverso i suoi canali ufficiali o segnalarlo
                      inoltrandogli l'e-mail.
                    </Text>

                    <Heading
                      as="h5"
                      fontSize="lg"
                      fontWeight={"400"}
                      textAlign={"left"}
                      fontFamily={"body"}
                      my={4}
                    >
                      VISHING
                    </Heading>
                    <Text>
                      All'improvviso ricevi una chiamata da qualcuno che afferma
                      di essere la tua banca. Sembra una cosa seria e ascolti
                      attentamente mentre ti dicono che, per motivi di
                      sicurezza, hanno bisogno di controllare rapidamente alcuni
                      dettagli sul tuo account cliente. Questo potrebbe essere
                      un caso di vishing, un tipo di frode intentata tramite
                      chiamate vocali. Se ciò accade, dedica qualche secondo a
                      pensare: se non sei sicuro non rispondere. Puoi sempre
                      contattare la tua banca tramite i canali ufficiali, il
                      sito web e l'app oppure recarti in una filiale dove
                      risponderanno alle tue domande. Ricorda che HCBE non ti
                      chiederà mai di fornire i dettagli di accesso completi
                      alla tua area riservata.
                    </Text>

                    <Heading
                      as="h5"
                      fontSize="lg"
                      fontWeight={"400"}
                      textAlign={"left"}
                      fontFamily={"body"}
                      my={4}
                    >
                      SMISHING
                    </Heading>
                    <Text>
                      Ricevi un SMS che ti informa che è stato effettuato un
                      acquisto con la tua carta di credito in un negozio, che tu
                      sei certo di non aver effettuato, quindi ti senti
                      allarmato. Nel testo ti viene chiesto di fare clic su un
                      link per risolvere il problema. Pensaci due volte: questo
                      potrebbe essere un tentativo di smishing, che è un metodo
                      di frode tramite SMS. Non preoccuparti: puoi controllare
                      le transazioni sul tuo conto bancario o chiamare
                      direttamente la tua banca.
                    </Text>
                  </OrderedList>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {/* ITEM #4 */}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton {...collapsedProps} {...expandedProps}>
                  <Box textAlign="left">
                    <Heading
                      as="h3"
                      size="md"
                      fontWeight="400"
                      py={2}
                      fontFamily={"body"}
                      _hover={{ color: "accent.01" }}
                    >
                      4. Tieni al sicuro le tue password
                    </Heading>

                    <Text fontSize={"15px"} color={"utils.gray.02"} mr={4}>
                      Se sei solito utilizzare la stessa password per tutti i
                      tuoi account, questo potrebbe interessarti. Scopri come
                      creare password complesse, che siano facili da ricordare.
                      Più lunga è la password, più difficile sarà decifrarla.
                    </Text>
                  </Box>

                  <ToggleButton expanded={isExpanded} />
                </AccordionButton>

                <AccordionPanel pb={"60px"}>
                  <Text>
                    Avere password uguali o simili per tutti i tuoi account è
                    una pratica molto comune, anche se non consigliabile. Se
                    questo è il tuo caso, ti interesseranno i seguenti
                    suggerimenti sulla sicurezza, che potrebbero aiutarti a
                    cambiare questa abitudine e migliorare il modo in cui
                    gestisci le tue password.
                  </Text>

                  <OrderedList my={5}>
                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        my={4}
                      >
                        Utilizza passphrase
                      </Heading>
                      <Text>
                        Una passphrase è una password composta da 3 o più
                        parole. Ad esempio, ci vorrà parecchio tempo per
                        decifrare "pollocanegattoconiglio", il doppio di quello
                        necessario ad un algoritmo per decifrare "#sK8/aD+" ed è
                        molto più facile da memorizzare. Ricorda che ciò che
                        rende forte una password è la sua lunghezza, non la sua
                        complessità.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        my={4}
                      >
                        Utilizza l'autenticazione a più fattori (MFA)
                      </Heading>

                      <Text>
                        Oltre ad avere una password complessa, utilizza
                        l'autenticazione a più fattori (MFA) quando possibile.
                        Questo metodo richiede ulteriori forme di
                        identificazione che solo tu possiedi: un codice inviato
                        al tuo cellulare, l'impronta digitale o il
                        riconoscimento facciale.
                      </Text>
                    </ListItem>

                    <ListItem>
                      <Heading
                        as="h4"
                        fontSize="lg"
                        fontWeight={"400"}
                        textAlign={"left"}
                        fontFamily={"body"}
                        my={4}
                      >
                        Utilizza un gestore di password
                      </Heading>

                      <Text>
                        Non annotare le tue password su un pezzo di carta o
                        nelle note sul tuo telefono cellulare. Utilizza invece
                        un gestore di password. Queste applicazioni ti
                        consentono di salvare tutte le tue password in un unico
                        posto e devi solo ricordare un codice principale per
                        accedervi.
                      </Text>
                    </ListItem>
                  </OrderedList>

                  <Heading
                    as="h4"
                    fontSize="lg"
                    fontWeight={"400"}
                    textAlign={"left"}
                    fontFamily={"body"}
                    color={"accent.01"}
                    my={5}
                  >
                    Non ripetere le password ed evita quelle predefinite
                  </Heading>

                  <Text>
                    Ricorda che l'opzione più sicura è quella di non utilizzare
                    la stessa password più di una volta. Se lo fai, dovrai
                    cambiarla ovunque nel caso in cui uno dei tuoi account sia
                    compromesso.
                    <br />
                    Se hai un sospetto, segnalalo.
                  </Text>

                  <Text>
                    Ti consigliamo inoltre di modificare quelle password scelte
                    per impostazione predefinita o che vengono fornite
                    all’acquisto di un dispositivo, come la password del router
                    Wi-Fi. Personalizzale per aumentare la loro sicurezza.
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>

          {/* ITEM #5 */}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton {...collapsedProps} {...expandedProps}>
                  <Box textAlign="left">
                    <Heading
                      as="h3"
                      size="md"
                      fontWeight="400"
                      py={2}
                      fontFamily={"body"}
                      _hover={{ color: "accent.01" }}
                    >
                      5. Se hai un sospetto, segnalalo
                    </Heading>

                    <Text fontSize={"15px"} color={"utils.gray.02"} mr={4}>
                      Se ricevi una e-mail in cui ti si chiede di fornire le tue
                      password, i dettagli del conto bancario o altre
                      informazioni personali e questo ti insospettisce,
                      segnalalo. Scopri come puoi farlo e come aiutare gli
                      altri.
                    </Text>
                  </Box>

                  <ToggleButton expanded={isExpanded} />
                </AccordionButton>

                <AccordionPanel pb={"60px"}>
                  <Text>
                    Se ricevi una e-mail con informazioni che non hai chiesto,
                    da qualcuno che non conosci o qualcuno ti chiama chiedendoti
                    informazioni personali, segnalalo. Gli attacchi informatici
                    possono manifestarsi in molti modi, quindi non abbassare la
                    guardia e segnala ciò che ritieni sospetto.
                  </Text>

                  <Heading
                    as="h4"
                    fontSize="lg"
                    fontWeight={"400"}
                    textAlign={"left"}
                    fontFamily={"body"}
                    color={"accent.01"}
                    mt={10}
                    mb={2}
                  >
                    Cosa fare se si riceve una comunicazione sospetta da parte
                    di HCBE?
                  </Heading>
                  <Text>
                    Per mantenere i tuoi dati al sicuro, evita di condividere le
                    informazioni se non sei certo che una richiesta sia
                    legittima. Se hai dubbi che provenga realmente da HCBE,
                    contattaci in modo che possiamo prendere misure opportune e
                    proteggere te e gli altri clienti:
                    <UnorderedList>
                      <ListItem>
                        <Link
                          color="accent.01"
                          href="mailto:servizioclienti@it.hcs.com"
                          isExternal
                        >
                          servizioclienti@it.hcs.com
                        </Link>
                      </ListItem>

                      <ListItem>848800259</ListItem>
                    </UnorderedList>
                  </Text>

                  <Heading
                    as="h4"
                    fontSize="lg"
                    fontWeight={"400"}
                    textAlign={"left"}
                    fontFamily={"body"}
                    color={"accent.01"}
                    mt={10}
                    mb={2}
                  >
                    Cosa fare se si riceve una comunicazione sospetta da un
                    altro soggetto, diverso da HCBE?
                  </Heading>
                  <Text>
                    Nel caso in cui qualcuno cerchi di ingannarti, questo è
                    quello che puoi fare:
                    <UnorderedList>
                      <ListItem>
                        Non rispondere alla comunicazione e non cliccare sui
                        link se presenti. Contatta l’azienda attraverso i canali
                        ufficiali indicati sul loro sito web
                      </ListItem>
                      <ListItem>
                        In alternativa puoi sempre contattare la Polizia
                        Postale:{" "}
                        <Link
                          color="accent.01"
                          href="https://www.commissariatodips.it/"
                          isExternal
                        >
                          www.commissariatodips.it
                        </Link>
                      </ListItem>
                    </UnorderedList>
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Box>
    </SectionLayout>
  );
};

export default SicurezzaInRetePage;
