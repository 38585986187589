import { Box, Button, Container, Stack } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

export type TPages =
  | "reclami"
  | "inadempimenti-abf"
  | "sicurezza-in-rete"
  | "credito-consapevole";

interface IProps {
  page?: TPages;
}

const buttonProps = {
  borderRadius: 0,
  bg: "transparent",
  fontWeight: "normal",
  fontSize: 18,
  color: "#1A1A1A",
  py: 18,
  px: "1rem",
  height: "auto",
};

export const Menu: React.FC<IProps> = ({ page }) => (
  <Box
    bg={"utils.white"}
    borderBottomWidth={1}
    borderBottomStyle="solid"
    borderBottomColor="gray.04"
  >
    <Container maxW={"container.md"} px={4}>
      <Stack
        direction={["column", "row"]}
        justify={["auto", "space-between"]}
        mb={[4, 0]}
      >
        <Button
          {...buttonProps}
          {...(page === "reclami"
            ? {
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                borderBottomColor: "accent.01",
                color: "accent.01",
              }
            : {})}
          as={GatsbyLink}
          to="/info-per-il-pubblico/reclami"
        >
          Reclami
        </Button>

        <Button
          {...buttonProps}
          {...(page === "credito-consapevole"
            ? {
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                borderBottomColor: "accent.01",
                color: "accent.01",
              }
            : {})}
          as={GatsbyLink}
          to="/info-per-il-pubblico/credito-consapevole"
        >
          Credito consapevole
        </Button>

        <Button
          {...buttonProps}
          {...(page === "inadempimenti-abf"
            ? {
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                borderBottomColor: "accent.01",
                color: "accent.01",
              }
            : {})}
          as={GatsbyLink}
          to="/info-per-il-pubblico/inadempimenti-abf"
        >
          Inadempimenti ABF
        </Button>

        <Button
          {...buttonProps}
          {...(page === "sicurezza-in-rete"
            ? {
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                borderBottomColor: "accent.01",
                color: "accent.01",
              }
            : {})}
          as={GatsbyLink}
          to="/info-per-il-pubblico/sicurezza-in-rete"
        >
          Sicurezza in rete
        </Button>
      </Stack>
    </Container>
  </Box>
);
